import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import BackLogo from "assets/images/fitfarm-logo-for-white-banner.svg"
import Logo from "assets/images/fitfarm-green-color-logo-only.svg"

const FourOFour = (props) => {
  return (
    <>
      <section className="banner banner-white">
        <div className="banner-crossfit">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A happy blonde woman in fitness clothes"
            className="banner-crossfit__img"
          />
        </div>
        <div className="banner-service__header">
          <img
            src={Logo}
            className="banner-service__logo"
            alt="fitfarm green colored logo"
          />
          <h1 className="type  type-heavy type-header__service type-break__after">
            This route does't exist
          </h1>

          <Link
            to="/"
            className="btn btn-primary btn-primary__gold type-heavy type-black u-mt-2"
          >
            Keep Browsing
          </Link>
        </div>
        <div className="banner-overlay"></div>
        <img
          src={BackLogo}
          className="img-background img-background__right"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default FourOFour
